const _error = {
    0: '正常',
    1: '内部错误',
    21: 'from非法',
    22: 'to非法',
    24: 'coords格式非法',
    25: 'coords个数非法，超过限制'
}

/**
 * 坐标系转换
 * @param {} points
 * @param {*} from 源坐标类型：
 *      1：GPS标准坐标；
        2：搜狗地图坐标；
        3：火星坐标（gcj02），即高德地图、腾讯地图和MapABC等地图使用的坐标；
        4：3中列举的地图坐标对应的墨卡托平面坐标;
        5：百度地图采用的经纬度坐标（bd09ll）；
        6：百度地图采用的墨卡托平面坐标（bd09mc）;
        7：图吧地图坐标；
        8：51地图坐标
 * @param {*} to 目标坐标类型：
        3：火星坐标（gcj02），即高德地图、腾讯地图及MapABC等地图使用的坐标；
        5：百度地图采用的经纬度坐标（bd09ll）；
        6：百度地图采用的墨卡托平面坐标（bd09mc）；
 * @return
    status:
        0	正常
        1	内部错误
        21	from非法
        22	to非法
        24	coords格式非法
        25	coords个数非法，超过限制
 */
const _convert = (convertor, idx, points, from, to) => {
    return new Promise((resolve, reject) => {
        convertor.translate(points, from, to, (data) => {
            // console.log(data);
            if(data.status === 0) {
                resolve({index: idx , data: data.points });
            } else {
                reject(_error[data.status] || '坐标转换未知错误');
            }
        })
    });
}

export const convert = (points, from, to, latKey='lat', lngKey='lng') => {
    const convertor = new BMap.Convertor();
    const chunkSize = 10;
    let result = [...points]
    let jobs = []
    for (let i = 0; i < points.length; i += chunkSize) {
        const chunk = points.slice(i, i + chunkSize);
        const ptArr = chunk.map(p => {
            if (p instanceof BMap.Point) {
                return p;
            } else {
                return new BMap.Point(p[lngKey], p[latKey]);
            }
        })
        // do whatever
        jobs.push(
            _convert(convertor, i, ptArr, from, to)
            .then(({index, data}) => {
                for (let j = 0; j < data.length; j++) {
                    result[j + index][latKey] = data[j].lat;
                    result[j + index][lngKey] = data[j].lng;
                }
                // result.splice(index, data.length, ...data)
            })
        );
    }
    return Promise.all(jobs).then(() => {
        // let pts = result.map(p => {return {longitude: p.lng, latitude: p.lat}});
        console.log(`converted coord from ${from} => to ${to}`)
        // console.log(points)
        // console.log(result)
        return result;
    })
}

export const bd09_to_gcj02 = (points, latKey='lat', lngKey='lng') => {
    return convert(points, 5, 3, latKey, lngKey)
}

export const gcj02_to_bd09 = (points, latKey='lat', lngKey='lng') => {
    return new Promise((resolve, reject) => {
        try {
            const convertedPoints = convert(points, 3, 5, latKey, lngKey);
            console.log("转换后的点", convertedPoints); // 添加调试信息
            resolve(convertedPoints);
        } catch (error) {
            console.error("转换出错", error);
            reject(error);
        }
    });
    // return convert(points, 3, 5, latKey, lngKey)
}
